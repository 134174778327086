import type { App } from "vue";
import UiKit from "draivn-ui-kit";
import "draivn-ui-kit/vendor.css";
import "draivn-ui-kit/draivn-ui-kit.css";
import { Input } from "draivn-ui-kit/components/input";
import "draivn-ui-kit/components/input.css";
import { Btn } from "draivn-ui-kit/components/button";
import "draivn-ui-kit/components/button.css";
import { Icon } from "draivn-ui-kit/components/icon";
import "draivn-ui-kit/components/icon.css";
import { Snackbar } from "draivn-ui-kit/components/snackbar";
import "draivn-ui-kit/components/snackbar.css";
import { PasswordInput } from "draivn-ui-kit/components/password-input";
import "draivn-ui-kit/components/password-input.css";
import { Radio } from "draivn-ui-kit/components/radio";
import { Checkbox } from "draivn-ui-kit/components/checkbox";
import { DateInput } from "draivn-ui-kit/components/date-input";
import "draivn-ui-kit/components/date-input.css";
import { Select } from "draivn-ui-kit/components/select";
import "draivn-ui-kit/components/select.css";
import "draivn-ui-kit/components/menu.css";
import "draivn-ui-kit/components/field.css";
import "draivn-ui-kit/components/virtual-scroll.css";
import { Item, ItemSection, ItemLabel } from "draivn-ui-kit/components/item";
import "draivn-ui-kit/components/item.css";
import { Separator } from "draivn-ui-kit/components/separator";
import "draivn-ui-kit/components/separator.css";
import { Layout } from "draivn-ui-kit/components/layout";
import { Drawer } from "draivn-ui-kit/components/drawer";
import { Page, PageContainer } from "draivn-ui-kit/components/page";
import { Dialog } from "draivn-ui-kit/components/dialog";
import "draivn-ui-kit/components/dialog.css";
import { Menu } from "draivn-ui-kit/components/menu";
import { Avatar } from "draivn-ui-kit/components/avatar";
import { Tooltip, IconTooltip } from "draivn-ui-kit/components/tooltip";
import "draivn-ui-kit/components/tooltip.css";
import {
  Stepper,
  Step,
  StepperNavigation,
} from "draivn-ui-kit/components/stepper";
import "draivn-ui-kit/components/stepper.css";
import { Breadcrumbs } from "draivn-ui-kit/components/breadcrumbs";
import "draivn-ui-kit/components/breadcrumbs.css";
import { CircularProgress } from "draivn-ui-kit/components/circular-progress";
import "draivn-ui-kit/components/circular-progress.css";
import { Header } from "draivn-ui-kit/components/header";
import "draivn-ui-kit/components/toast";
import "draivn-ui-kit/components/toast.css";

export const UiKitService = UiKit;

export default async (app: App) => {
  const gridLicenseKey = process.env.VUE_APP_GRID_KEY;
  if (gridLicenseKey) {
    app.use(UiKit, {
      gridLicenseKey,
    });
    app
      .component("DCheckbox", Checkbox)
      .component("DRadio", Radio)
      .component("DDateInput", DateInput)
      .component("DSelect", Select)
      .component("DPasswordInput", PasswordInput)
      .component("DInput", Input)
      .component("DBtn", Btn)
      .component("DSnackbar", Snackbar)
      .component("DIcon", Icon)
      .component("DItem", Item)
      .component("DItemSection", ItemSection)
      .component("DItemLabel", ItemLabel)
      .component("DSeparator", Separator)
      .component("DLayout", Layout)
      .component("DDrawer", Drawer)
      .component("DHeader", Header)
      .component("DPage", Page)
      .component("DPageContainer", PageContainer)
      .component("DDialog", Dialog)
      .component("DMenu", Menu)
      .component("DAvatar", Avatar)
      .component("DCircularProgress", CircularProgress)
      .component("DTooltip", Tooltip)
      .component("DStepper", Stepper)
      .component("DStep", Step)
      .component("DStepperNavigation", StepperNavigation)
      .component("DBreadcrumbs", Breadcrumbs)
      .component("DIconTooltip", IconTooltip);
  } else {
    throw new Error("Grid license key is not provided");
  }
};
